<template>
  <div>
    <Breadcrumbs>
      <router-link to="/home/member-info">
        {{ $t("menu.main.membershipInformation") }}
      </router-link>
      >
      {{ $route.meta.title }}
    </Breadcrumbs>
    <div class="form-container px-5 pb-15">
      <!-- START Deputy-member -->
      <!-- permanently hidden. See https://kujira.backlog.com/view/TO2020-551#comment-232598337 -->
      <template v-if="false && hasViceMember">
        <div class="text-heading--prm --w-medium text-center pt-6 pb-3">
          {{ $t("memberInfo.deputyMember") }}
        </div>
        <div v-for="item in viceMembers" :key="item.id">
          <MemberItem
            :id="item.id"
            :isDeputy="true"
            :name="item.name"
            :email="item.memberUser && item.memberUser.email"
            disable-change
          />
        </div>
      </template>
      <!-- END Deputy-member -->

      <!--========== START Family-members ==========-->
      <h2 class="text-heading--c-prm text-center pt-6 pb-3">
        {{ $t("memberInfo.familyMember") }}
      </h2>
      <div v-for="item in familyMembers" :key="item.id">
        <MemberItem
          :id="item.id"
          :name="item.name"
          :email="item.memberUser && item.memberUser.email"
        />
      </div>

      <!-- Button-add-family-member -->
      <v-btn
        v-if="!$isCompanyUser()"
        elevation="0"
        x-large
        :to="`/home/member-info/member/new/`"
        rounded
        block
        class="mt-5"
        color="btn-grad--orange"
      >
        <v-icon class="mr-3">mdi-plus</v-icon>
        {{ $t("buttons.addFamilyMembers") }}
      </v-btn>
      <!--========== END Family-members ============-->
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '../../../components/Breadcrumbs'
import MemberItem from '../../../components/MemberItem'

export default {
  name: 'MemberInfoMember',
  components: {
    Breadcrumbs,
    MemberItem
  },
  async mounted () {
    await this.$doLoading(async () => {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('mySubMemberList', {
          apollo: this.$apollo
        })
      })
    })
  },
  computed: {
    mySubMemberList () {
      return this.$store.state.memberInfo.mySubMemberList
    },
    viceMembers () {
      return this.mySubMemberList.filter(m => m.subMemberTypeId === 1)
    },
    hasViceMember () {
      return !!this.viceMembers.length
    },
    familyMembers () {
      return this.mySubMemberList.filter(m => m.subMemberTypeId === 2)
    }
  }
}
</script>
